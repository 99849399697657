import * as React from "react";
import * as styles from "./SectionHomeLanding.module.scss";

import { Button } from "../../../Button/Button";
import MyImage from "../../../../images/AvatarMe.png";

const SectionHomeLanding = ({ tweenRef }) => {
  return (
    <section className={styles.section_container}>
      <div className={styles.hero_wrapper}>
        <div ref={tweenRef} className={styles.hero_container}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1rem",
            }}
          >
            <div className={styles.avatarWrapper}>
              <img src={MyImage} alt="Avatar" />
            </div>
          </div>
          <h1>Hello There </h1>
          <p>
            I'm Sufyan, a UI developer who pays too much attention on UI components. With experience
            as both a UX designer and front-end developer, I work towards bridging the gap between
            design and development, so that designers and developers don't kill one other.
          </p>
          <div className={styles.button_wrapper}>
            <Button url="mailto:sufyazman@gmail.com">Say Hello</Button>
            <Button to="/about"> About Me</Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionHomeLanding;
