import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { useWebMountContext } from "../context/PageLoadContext.js";

import SectionHomeLanding from "../components/Sections/Home/SectionHomeLanding/SectionHomeLanding.js";

const IndexPage = () => {
  const secLandingRef = useRef();
  const { isLayoutMounted } = useWebMountContext();

  // --------------------------
  // Page Load Animation
  // --------------------------

  useEffect(() => {
    // ------------------
    // Only do on Desktop
    // -----------------
    if (window.innerWidth > 600 && !isLayoutMounted) {
      const t1 = gsap.timeline();
      t1.from(
        secLandingRef.current,
        {
          opacity: 0,
          duration: 0.5,
        },
        1.5
      );
    }
  }, [isLayoutMounted]);

  return (
    <>
      <SectionHomeLanding tweenRef={secLandingRef} />
    </>
  );
};

export default IndexPage;
